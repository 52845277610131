import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "recap"
    }}>{`Recap`}</h2>
    <Vimeo id={424608233} mdxType="Vimeo" />
    <p>{`There's a lot more D3 can do, but those are the 3 key insights you need to
understand any example you find in the wild.`}</p>
    <ol>
      <li parentName="ol">{`Code is split into data and DOM manipulation`}</li>
      <li parentName="ol">{`Scales are great and used a lot`}</li>
      <li parentName="ol">{`You're always in control of rendering`}</li>
    </ol>
    <p>{`Then you can start using D3's more advanced features like maps and axes and
shape generators and geo computations and data loading and transitions and user
interactions.`}</p>
    <p>{`There's a lot. We're not going to cover it all but you can find those advanced
features in the docs and the skills you learn here will help you get started.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      